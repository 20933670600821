(function () {
    'use strict';
    angular
        .module('bozorakaAdminApp')
        .factory('PayFormBalance', PayFormBalance)
        .factory('HermesBalance', HermesBalance)
        .factory('PayFormRate', PayFormRate)
        .factory('HermesRate', HermesRate);

    PayFormBalance.$inject = ['$resource'];

    function PayFormBalance($resource) {
        var resourceUrl = 'api/payform-balance';

        return $resource(resourceUrl, {}, {
            'query': {method: 'GET'}

        });
    }

    HermesBalance.$inject = ['$resource'];

    function HermesBalance($resource) {
        var resourceUrl = 'api/hermes-balance';

        return $resource(resourceUrl, {}, {
            'query': {method: 'GET', isArray: true}

        });
    }

    PayFormRate.$inject = ['$resource'];

    function PayFormRate($resource) {
        var resourceUrl = 'api/payform-rate';

        return $resource(resourceUrl, {}, {
            'query': {method: 'GET', isArray: true}
        });
    }

    HermesRate.$inject = ['$resource'];

    function HermesRate($resource) {
        var resourceUrl = 'api/hermes-rate';

        return $resource(resourceUrl, {}, {
            'query': {method: 'GET', isArray: true}
        });
    }
})();
