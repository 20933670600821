/**
 * Created by Gayratjon Rayimjonov on 10/24/2017.
 */

(function () {
    'use strict';

    angular
        .module('bozorakaAdminApp')
        .controller('TerminalPaymentController', TerminalPaymentController);

    TerminalPaymentController.$inject = ['$state', 'VirtualBankTransaction', 'DataUtils', 'paginationConstants', 'pagingParams', 'ProfileIdByAccount', 'VirtualBankTransactionTotalAmount'];

    function TerminalPaymentController($state, VirtualBankTransaction, DataUtils, paginationConstants, pagingParams, ProfileIdByAccount, VirtualBankTransactionTotalAmount) {
        var vm = this;

        vm.itemsPerPage = 20;
        vm.page = pagingParams.page;
        vm.searchQuery = {
            name: pagingParams.name,
            accountNumber: pagingParams.accountNumber,
            amount: pagingParams.amount,
            fromDate: pagingParams.fromDate,
            toDate: pagingParams.toDate,
        };

        vm.changePage = changePage;
        vm.transition = transition;
        vm.openProfile = openProfile;
        vm.clear = clear;
        vm.search = search;
        vm.convertDateTime = convertDateTime;
        vm.getBankNameByIndex = getBankNameByIndex;

        getVirtualBankTransaction();
        initDatePicker();
        getTotalAmount();

        function getVirtualBankTransaction() {
            VirtualBankTransaction.get({
                name: pagingParams.name,
                accountNumber: pagingParams.accountNumber,
                amount: pagingParams.amount,
                fromDate: pagingParams.fromDate,
                toDate: pagingParams.toDate,
                page: pagingParams.page,
                size: vm.itemsPerPage
            }, onSuccess);

            function onSuccess(data) {
                vm.virtualBankTransaction = data.transactions.reverse();
                vm.queryCount = data.count;
            }
        }

        function transition() {
            $state.transitionTo($state.$current, {
                name: vm.searchQuery.name,
                accountNumber: vm.searchQuery.accountNumber,
                amount: vm.searchQuery.amount,
                fromDate: vm.searchQuery.fromDate,
                toDate: vm.searchQuery.toDate,
                page: vm.page,
            });
        }

        function search(searchQuery) {
            $('.input-daterange input').each(function () {
                if ($(this).attr('name') == 'from') {
                    vm.searchQuery.fromDate = $(this).val();
                } else if ($(this).attr('name') == 'to') {
                    vm.searchQuery.toDate = $(this).val();
                }
            });
            vm.page = 1;
            vm.transition();
        }

        function clear() {
            vm.page = 1;
            vm.searchQuery = {
                name: null,
                accountNumber: null,
                amount: null,
                fromDate: null,
                toDate: null
            };
            vm.transition();
        }

        function initDatePicker() {
            $('.input-daterange input').each(function () {
                if ($(this).attr('name') == 'from') {
                    $(this).val(vm.searchQuery.fromDate);
                } else if ($(this).attr('name') == 'to') {
                    $(this).val(vm.searchQuery.toDate);
                }
            });

            $('.date-picker').datepicker({autoclose: true});
        }

        function convertDateTime(date, time) {
            return convertDate(date) + ' ' + convertTime(time);
        }

        function convertDate(date) {
            return date.substring(0, 4) + '.' + date.substring(4, 6) + '.' + date.substring(6, 8);
        }

        function convertTime(time) {
            return time.substring(0, 2) + ':' + time.substring(2, 4);
        }

        function getBankNameByIndex(bankCode) {
            var index = getBankIndexByCode(bankCode);
            if (index == null) {
                return bankCode;
            }
            return DataUtils.getBankNameByIndex(index);
        }

        function getBankIndexByCode(code) {
            switch (code) {
                case "000": return 0;
                case "003": return 1;
                case "039": return 2;
                case "020": return 3;
                case "081": return 4;
                case "088": return 5;
                case "004": return 6;
                case "011": return 7;
                case "023": return 8;
                default: return null;
            }
        }

        function changePage(page) {
            vm.page = page;
            vm.transition();
        }

        function openProfile(accountNumber) {
            ProfileIdByAccount.get({accountNumber: accountNumber}, onSuccess);

            function onSuccess(data) {
                $state.go('profile-detail', {id: data.profileId});
            }
        }

        function getTotalAmount() {
            VirtualBankTransactionTotalAmount.get({
                name: vm.searchQuery.name,
                accountNumber: vm.searchQuery.accountNumber,
                amount: vm.searchQuery.amount,
                fromDate: vm.searchQuery.fromDate,
                toDate: vm.searchQuery.toDate
            }, onSuccess);

            function onSuccess(data) {
                vm.totalAmount = data.totalAmount;
            }
        }

    }
})();

