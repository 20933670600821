(function() {
    'use strict';

    angular
        .module('bozorakaAdminApp')
        .controller('FlashSaleHistoryController', FlashSaleHistoryController);

    FlashSaleHistoryController.$inject = ['$state', 'FlashSaleHistory', 'ParseLinks', 'AlertService', 'paginationConstants', 'pagingParams', '$stateParams'];

    function FlashSaleHistoryController($state, FlashSaleHistory, ParseLinks, AlertService, paginationConstants, pagingParams, $stateParams) {

        var vm = this;

        vm.loadPage = loadPage;
        vm.search = search;
        vm.clear = clear;
        vm.predicate = pagingParams.predicate;
        vm.reverse = pagingParams.ascending;
        vm.transition = transition;
        vm.itemsPerPage = paginationConstants.itemsPerPage;
        vm.id = $stateParams.id;
        vm.totalSold = 0;
        vm.loadAll = loadAll;

        loadAll();

        function loadAll() {
            FlashSaleHistory.query({
                id: vm.id,
                page: pagingParams.page - 1,
                size: vm.itemsPerPage,
                sort: sort()
            }, onSuccess, onError);

            function sort() {
                var result = [vm.predicate + ',' + (vm.reverse ? 'asc' : 'desc')];
                if (vm.predicate !== 'id') {
                    result.push('id');
                }
                return result;
            }
            function onSuccess(data, headers) {
                vm.links = ParseLinks.parse(headers('link'));
                vm.totalItems = headers('X-Total-Count');
                vm.queryCount = vm.totalItems;
                vm.flashSaleHistories = data.flashSaleHistories;
                vm.totalSold = data.totalSold;
                vm.page = pagingParams.page;
            }
            function onError(error) {
                AlertService.error(error.data.message);
            }
        }
        function loadPage(page) {
            vm.page = page;
            vm.transition();
        }

        function transition() {
            $state.transitionTo($state.$current, {
                id: vm.id,
                page: vm.page,
                sort: vm.predicate + ',' + (vm.reverse ? 'asc' : 'desc'),
                search: vm.currentSearch,
            });
        }

        function search() {
            vm.links = null;
            vm.page = 1;
            vm.predicate = 'id';
            vm.reverse = false;
            vm.transition();
        }
        function clear() {
            vm.links = null;
            vm.page = 1;
            vm.predicate = 'id';
            vm.reverse = false;
            vm.transition();
        }
    }
})();
