(function() {
    'use strict';

    angular
        .module('bozorakaAdminApp')
        .controller('PartnerExchangeRateController', PartnerExchangeRateController);

    PartnerExchangeRateController.$inject = ['$state', '$localStorage', 'PayFormRate', 'HermesRate', '$timeout'];

    function PartnerExchangeRateController($state, $localStorage, PayFormRate, HermesRate, $timeout) {

        var vm = this;

        vm.loadAll = loadAll;
        vm.loadFromLocalStorage = loadFromLocalStorage;
        vm.getCountryName = getCountryName;

        vm.loadAll();
        vm.loadFromLocalStorage();

        function loadAll() {
            PayFormRate.query(function onSuccess(data) {
                var existingData = JSON.parse(localStorage.getItem('payformRate')) || [];
                var updatedData = mergePayFormData(existingData, data);
                localStorage.setItem('payformRate', JSON.stringify(updatedData));

                $timeout(function() {
                    vm.payformRates = updatedData;
                });
            });

            HermesRate.query(function onSuccess(data) {
                var existingData = JSON.parse(localStorage.getItem('hermesRate')) || [];
                var updatedData = mergeHermesData(existingData, data);
                localStorage.setItem('hermesRate', JSON.stringify(updatedData));

                $timeout(function() {
                    vm.hermesRates = updatedData;
                });
            });
        }

        function mergeHermesData(existingData, newData) {
            var dataMap = {};
            angular.forEach(existingData, function(item) {
                dataMap[item.Service] = item;
            });
            angular.forEach(newData, function(item) {
                dataMap[item.Service] = angular.extend({}, item, { updatedAt: new Date().toISOString() });
            });
            return Object.values(dataMap);
        }

        function mergePayFormData(existingData, newData) {
            var dataMap = {};
            angular.forEach(existingData, function(item) {
                dataMap[item.countryCurrency] = item;
            });
            angular.forEach(newData, function(item) {
                dataMap[item.countryCurrency] = angular.extend({}, item, { updatedAt: new Date().toISOString() });
            });
            return Object.values(dataMap);
        }

        function loadFromLocalStorage() {
            var payformRateData = localStorage.getItem('payformRate');
            if (payformRateData) {
                vm.payformRates = JSON.parse(payformRateData);
            }
            var hermesRateData = localStorage.getItem('hermesRate');
            if (hermesRateData) {
                vm.hermesRates = JSON.parse(hermesRateData);
            }
        }

        function getCountryName(currency) {
            var countryMap = {
                'KZT': 'Kazakhstan',
                'KGS': 'Kyrgyzstan',
                'TJS': 'Tajikistan',
                'RUB': 'Russia',
                'UZS': 'Uzbekistan'
            };
            return countryMap[currency] ? countryMap[currency] : '';
        }

        vm.showJsonModal = function (jsonData) {
            try {
                vm.selectedJson = jsonData;
            } catch (e) {
                console.error("Invalid JSON string", e);
                vm.selectedJson = {error: "Invalid JSON format"};
            }
            $('#jsonModal').modal('show');
        };
    }
})();
