(function () {
    'use strict';

    angular
        .module('bozorakaAdminApp')
        .controller('RequestController', RequestController);

    RequestController.$inject = ['$scope', '$state', '$uibModal', 'Request', 'ParseLinks', 'AlertService', 'paginationConstants', 'pagingParams', '$interval', 'DateUtils', 'DataUtils'];

    function RequestController($scope, $state, $uibModal, Request, ParseLinks, AlertService, paginationConstants, pagingParams, $interval, DateUtils, DataUtils) {
        var vm = this;

        vm.loadPage = loadPage;
        vm.predicate = pagingParams.predicate;
        vm.reverse = pagingParams.ascending;
        vm.transition = transition;
        vm.itemsPerPage = paginationConstants.itemsPerPage;
        vm.loadAll = loadAll;
        vm.dateDiff = DateUtils.transactionDateDifference;
        vm.getDeductedAmount = DataUtils.getDeductedAmount;
        vm.getSentAmount = DataUtils.getSentAmount;
        vm.openStatusEditDialog = openStatusEditDialog;
        vm.getDisplayAmount = getDisplayAmount;

        loadAll();

        function loadAll() {
            Request.query({
                page: pagingParams.page - 1,
                size: vm.itemsPerPage,
                sort: sort()
            }, onSuccess, onError);

            function sort() {
                var result = [vm.predicate + ',' + (vm.reverse ? 'asc' : 'desc')];
                if (vm.predicate !== 'id') {
                    result.push('id');
                }
                return result;
            }

            function onSuccess(data, headers) {
                vm.links = ParseLinks.parse(headers('link'));
                vm.totalItems = headers('X-Total-Count');
                vm.queryCount = vm.totalItems;
                vm.transactions = data;
                vm.page = pagingParams.page;
                setTimeout(function () {
                    enableClipboard();
                }, 0);
            }

            function onError(error) {
                AlertService.error(error.data.message);
            }
        }

        function loadPage(page) {
            vm.page = page;
            vm.transition();
        }

        function transition() {
            $state.transitionTo($state.$current, {
                page: vm.page,
                sort: vm.predicate + ',' + (vm.reverse ? 'asc' : 'desc')
            });
        }

        function openStatusEditDialog(transactionId, status){
            $uibModal.open({
                templateUrl: 'app/entities/request/request-status-dialog.html',
                controller: 'RequestStatusDialogController',
                controllerAs: 'vm',
                backdrop: 'static',
                size: 'md',
                resolve: {
                    entity: ['Transaction', function (Transaction) {
                        return Transaction.get({id: transactionId}).$promise;
                    }],
                    status: function () {
                        return status;
                    }
                }
            }).result.then(function () {
                $state.go('request', {}, {reload: true});
            }, function () {
            });
        }

        function getDisplayAmount(transaction) {
            var received = null;
            if (transaction.providerSegmentDisplayRate) {
                var discount = 1;
                if (transaction.client === 'hanpass' || transaction.client === '010pay') {
                    discount = 0.96;
                }
                received = ((transaction.amount + transaction.directAmount + transaction.usedBonusAmount) / discount) * transaction.providerSegmentDisplayRate;
            }
            return received;
        }

        function enableClipboard() {
            var clipboardClass = document.getElementsByClassName('mt-clipboard');
            var clipboard = new Clipboard(clipboardClass);
        }

        //Put in interval, first trigger after 10 seconds
        var theInterval = $interval(function(){
            vm.loadAll();
        }.bind(this), 60000);

        $scope.$on('$destroy', function () {
            $interval.cancel(theInterval)
        });
    }
})();
