(function() {
    'use strict';

    angular
        .module('bozorakaAdminApp')
        .controller('FlashSaleProductsDialogController', FlashSaleProductsDialogController);

    FlashSaleProductsDialogController.$inject = ['$timeout', '$uibModal', '$state', '$filter', '$translate', '$scope', '$stateParams', '$uibModalInstance', 'paginationConstants', 'pagingParams', 'FlashSale', 'Product', 'ProductFlashSale', 'ProductsList', 'Category', 'AlertService'];

    function FlashSaleProductsDialogController ($timeout, $uibModal, $state, $filter, $translate, $scope, $stateParams, $uibModalInstance, paginationConstants, pagingParams, FlashSale, Product, ProductFlashSale, ProductsList, Category, AlertService) {
        var vm = this;
        var translate = $filter('translate'), all, noneSelected, allSelected;

        vm.close = close;
        vm.datePickerOpenStatus = {};
        vm.products = [];
        vm.selectedProduct = null;

        vm.productStatuses = ["AVAILABLE", "OUT_OF_STOCK", "DISABLED"];
        vm.parentCategoryField = pagingParams.parentCategoryField;
        vm.childCategoryField = pagingParams.childCategoryField;
        vm.childCategoryList = pagingParams.childCategoryList;
        vm.searchQuery = {
            productName: pagingParams.productName,
            sku: pagingParams.sku,
            category: pagingParams.category,
            statuses: pagingParams.statuses,
            parentCategories: pagingParams.parentCategories,
            childCategories: pagingParams.childCategories
        };
        vm.parentCategoryField = null;
        vm.onSelectCategory = onSelectCategory;
        vm.categories = [];
        vm.categoryTree = [];
        vm.loadFilteredProducts = loadFilteredProducts;
        vm.clearSearch = clearSearch;
        vm.search = search;


        vm.selectProduct = function (product) {
            vm.selectedProduct = product;
            var modalInstance = $uibModal.open({
                templateUrl: 'app/entities/flash-sale/flash-sale-form-dialog.html',
                controller: 'FlashSaleFormDialogController',
                controllerAs: 'vm',
                backdrop: 'static',
                size: 'md',
                resolve: {
                    selectedProduct: function() {
                        return vm.selectedProduct;
                    }
                }
            });
            $uibModalInstance.close();

            modalInstance.result.then(function(result) {
                if (result.success) {
                    $state.go('flash-sale', null, { reload: true });
                }
            }, function() {

            });
        };

        loadCategories();

        $timeout(function (){
            angular.element('.form-group:eq(0)>input').focus();
        });

        function close () {
            $uibModalInstance.dismiss('cancel');
        }

        function loadCategories() {
            Category.getAllCategories({}, function (data) {
                vm.categories = data;
                translateCategoryName();
                vm.categories.sort(function (a, b) {
                    return a.parentId > b.parentId ? 1 : -1;
                });
                for (var j = 0; j < vm.categories.length; j++) {
                    if (vm.categories[j].parentId === null) {
                        vm.categoryTree.push(vm.categories[j]);
                    } else {
                        var index = vm.categoryTree.findIndex(function (a) {
                            return a.id === vm.categories[j].parentId;
                        });
                        if (index > -1) {
                            if (vm.categoryTree[index].children === undefined) {
                                vm.categoryTree[index].children = [];
                            }
                            vm.categoryTree[index].children.push(vm.categories[j]);
                        }

                    }
                }
                setTimeout(function () {
                    $('#field_parent_category').multiselect(
                        'setOptions',
                        {
                            selectAllText: all,
                            nonSelectedText: noneSelected,
                            allSelectedText: allSelected
                        });
                    $('#field_parent_category').multiselect('rebuild');
                }, 0);
            });
        }

        function translateCategoryName() {
            var currentLang = $translate.use();
            for (var i = 0; i < vm.categories.length; i++) {
                if (currentLang === 'en') {
                    vm.categories[i]['name'] = vm.categories[i]['nameEng'];
                } else {
                    vm.categories[i]['name'] = vm.categories[i]['nameUzb'];
                }
            }
        }

        function setCategory() {
            if (vm.parentCategoryField != null) {
                vm.searchQuery.parentCategories = [];
                vm.parentCategoryField.forEach(function (category) {
                    category = angular.fromJson(category.toString());
                    vm.searchQuery.parentCategories.push(category.id);
                });
            }
            if (vm.childCategoryField != null) {
                vm.searchQuery.childCategories = [];
                vm.childCategoryField.forEach(function (category) {
                    category = angular.fromJson(category.toString());
                    vm.searchQuery.childCategories.push(category.id);
                });
            }
        }

        function onSelectCategory() {
            var newChildCategoryList = [];
            vm.parentCategoryField.forEach(function (element) {
                var category = angular.fromJson(element.toString());
                newChildCategoryList.push.apply(newChildCategoryList, category.children);
            });
            if (vm.childCategoryList !== null && newChildCategoryList.length < vm.childCategoryList.length && vm.childCategoryField !== null) {
                vm.childCategoryList = vm.childCategoryList.filter(function(element) {
                    return !newChildCategoryList.some(function (child){ return (child.id === element.id)});
                });
                vm.childCategoryField = vm.childCategoryField.filter(function(element) {
                    element = angular.fromJson(element.toString());
                    return !vm.childCategoryList.some(function (child){ return (child.id === element.id)});
                });
            }
            vm.childCategoryList = newChildCategoryList;
            setTimeout(function () {
                $('#field_child_category').multiselect(
                    'setOptions',
                    {
                        selectAllText: all,
                        nonSelectedText: noneSelected,
                        allSelectedText: allSelected
                    });
                $('#field_child_category').multiselect('rebuild');
            }, 0);
        }

        function loadFilteredProducts () {
            ProductsList.query({
                productName: vm.searchQuery.productName === null ? '' : vm.searchQuery.productName,
                sku: vm.searchQuery.sku === null ? '' : vm.searchQuery.sku,
                category: vm.searchQuery.category,
                statuses: vm.searchQuery.statuses,
                parentCategories: vm.searchQuery.parentCategories,
                childCategories: vm.searchQuery.childCategories,
            }, onSuccess, onError);

            function onSuccess(data) {
                vm.products = data;
            }

            function onError(error) {
                AlertService.error(error.data.message);
            }
        }

        function clearSearch() {
            vm.links = null;
            vm.parentCategoryField = null;
            vm.childCategoryField = null;
            vm.searchQuery = {
                productName: null,
                sku: null,
                category: null,
                parentCategories: null,
                childCategories: null,
                statuses: ["AVAILABLE", "OUT_OF_STOCK"]
            };
            vm.childCategoryList = null;
            loadFilteredProducts();

        }

        function search() {
            setCategory()
            vm.links = null;
            vm.reverse = true;
            loadFilteredProducts();
        }

        setTimeout(function () {
            translateMultiSelect();

            $('#productStatus').multiselect({
                buttonClass: "mt-multiselect btn btn-default",
                includeSelectAllOption: true,
                dropRight: false,
                buttonWidth: '100%',
                selectAllText: translate('product.all'),
                nonSelectedText: translate('product.noneSelected'),
                allSelectedText: translate('product.allSelected')
            });

            $('#field_parent_category').multiselect({
                buttonClass: "mt-multiselect btn btn-default",
                includeSelectAllOption: true,
                dropRight: false,
                buttonWidth: '100%',
                selectAllText: translate('product.all'),
                nonSelectedText: translate('product.noneSelected'),
                allSelectedText: translate('product.allSelected'),
                maxHeight: 300
            });

            $('#field_child_category').multiselect({
                buttonClass: "mt-multiselect btn btn-default",
                includeSelectAllOption: true,
                dropRight: false,
                buttonWidth: '100%',
                selectAllText: translate('product.all'),
                nonSelectedText: translate('product.noneSelected'),
                allSelectedText: translate('product.allSelected'),
                maxHeight: 300
            });

        }, 0);

        function translateMultiSelect() {
            all = translate('product.all');
            noneSelected = translate('product.noneSelected');
            allSelected = translate('product.allSelected');
        }
    }
})();
