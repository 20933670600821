(function () {
    'use strict';

    angular
        .module('bozorakaAdminApp')
        .config(stateConfig);

    stateConfig.$inject = ['$stateProvider'];

    function stateConfig($stateProvider) {
        $stateProvider
            .state('terminal-payment', {
                parent: 'entity',
                url: '/terminal-payment?page',
                data: {
                    authorities: ['ROLE_ADMIN', 'ROLE_MANAGER'],
                    pageTitle: 'bozorakaAdminApp.settleBank.terminalPayment'
                },
                views: {
                    'content@': {
                        templateUrl: 'app/entities/terminal-payment/bozoraka/terminal-payment.html',
                        controller: 'TerminalPaymentController',
                        controllerAs: 'vm'
                    }
                },
                params: {
                    page: {
                        value: '1',
                        squash: true
                    },
                    name: null,
                    accountNumber: null,
                    amount: null,
                    fromDate: null,
                    toDate: null
                },
                resolve: {
                    pagingParams: ['$stateParams', 'PaginationUtil', function ($stateParams, PaginationUtil) {
                       return {
                            name: $stateParams.name,
                            accountNumber: $stateParams.accountNumber,
                            amount: $stateParams.amount,
                            fromDate: $stateParams.fromDate,
                            toDate: $stateParams.toDate,
                            page: PaginationUtil.parsePage($stateParams.page),
                        };
                    }],
                    translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
                        $translatePartialLoader.addPart('cardPayment');
                        $translatePartialLoader.addPart('global');
                        return $translate.refresh();
                    }]
                }
            })
            .state('terminal-payment.profile', {
                url: '/profile/{accountNumber}',
                data: {
                    authorities: ['ROLE_ADMIN', 'ROLE_MANAGER']
                },
                views: {
                    'content@': {
                        controller: 'TerminalProfileRedirectController',
                        controllerAs: 'vm'
                    }
                }
            });
    }

})();
